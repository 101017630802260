<template>
  <single-book-summary />
</template>

<script>
import SingleBookSummary from "../../../components/books/SingleBookSummary";

export default {
  name: "view-book",
  components: {
    SingleBookSummary
  }
};
</script>

<style></style>
